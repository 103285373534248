import { bus } from '../main';

// Gestion des réponses de serveur
const handleResponse = (response, successMessage = "Opération réussie.") => {
    if (response.status >= 200 && response.status <= 299) {
        bus.$emit('notification', { 
            type: 'success', 
            color: 'success', 
            notice: successMessage, 
            timeout: 5000 
        });
    }
    return response;
};

// Gestion des erreurs (serveur ou autres)
const handleError = (error, errorMessage = "Opération réussie.") => {
    let message = "Une erreur est survenue.";
    let color = 'error';

    if (error.response) {
        const status = error.response.status;
        let errorTitle = error.response.data?.title || null;
        if(status === 401) {
            errorTitle = error.response.data?.detail;
            if (errorTitle.trim() === "Les identifications sont erronées") {
                errorTitle = "Identifiant ou mot de passe incorrect";
            }
        }
        if (errorTitle) {
            // message = translations[errorTitle] || "Erreur inconnue";
            message = errorTitle;
        } else {
            switch (true) {
                case status >= 400 && status <= 499:
                    message = errorMessage;
                    color = "warning";
                    break;
                case status >= 500 && status <= 599:
                    message = "Erreur serveur. Réessayez plus tard.";
                    color = "error";
                    break;
                default:
                    message = `Erreur inconnue (${status})`;
                    color = "error";
            }
        }
    } else if (error.request) {
        message = "Pas de réponse du serveur.";
    } else {
        message = error.message;
    }

    bus.$emit('notification', { 
        type: 'error', 
        color, 
        notice: message, 
        timeout: 5000 
    });
    return Promise.reject(error);
};

// Gestion des erreurs de validation frontend (ex: champs vides)
const handleFrontEndValidationError = (validationError) => {
    let message = "Certains champs sont manquants ou invalides.";
    let color = 'error';
    bus.$emit('notification', { 
        type: 'error', 
        color, 
        notice: validationError, 
        timeout: 5000 
    });
    return Promise.reject(message);
};

export { handleResponse, handleError, handleFrontEndValidationError };
