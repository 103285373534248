<template>
  <v-app>
    <template v-if="!signedIn">
      <Login />
    </template>
    <template v-else>
      <NavSideBar />
      <v-app-bar app color="" dense light>
        <v-app-bar-nav-icon></v-app-bar-nav-icon>
        <v-toolbar-title class="overline">Tenders360</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="seDeconnecter">
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </v-app-bar>

      <v-main>
        <router-view />
      </v-main>
    </template>

    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="snackbarTimeout" @input="resetSnackbar">
      <div style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
        <span>{{ snackbarMessage }}</span>
        <span class="close-btn" @click="snackbar = false">X</span>
      </div>
    </v-snackbar>

  </v-app>
</template>

<script>
import NavSideBar from './components/NavSideBar.vue';
import { mapActions, mapGetters } from 'vuex';
import Login from './views/Login.vue';
import { bus } from './main';

export default {
  name: 'App',
  components: {
    NavSideBar,
    Login,
  },

  data() {
    return {
      snackbar: false,
      snackbarMessage: '',
      snackbarColor: 'error',
      snackbarTimeout: 10000,
    };
  },

  computed: mapGetters(['signedIn', 'getToken', 'getUser']),

  methods: {
    ...mapActions(['logout', 'whoAMI', 'auth']),
    
    seDeconnecter() {
      this.logout();
    },

    resetSnackbar() {
      if (!this.snackbar) {
        this.snackbarMessage = '';
        this.snackbarTimeout = 5000;
      }
    },
  },

  created() {
    this.whoAMI();
    let ar = { token: this.getToken };
    this.auth(ar);

    bus.$on('notification', ({ type, color, notice, timeout }) => {
      this.snackbarMessage = notice;
      this.snackbarColor = color || (type === 'success' ? 'success' : 'error');
      this.snackbarTimeout = timeout || 5000;
      this.snackbar = true;
    });
  },
};
</script>

<style scoped>
.close-btn {
  cursor: pointer;
  font-weight: bold;
}

.close-btn:hover {
  color: #FEFEFE;
  transform: scale(1.2);
  transition: all 0.2s ease;
}
</style>
