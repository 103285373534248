<template>
  <v-dialog v-model="dialog" persistent max-width="330">
    <v-card>
      <v-card-title class="text-wrap">{{ dialogTitle }}</v-card-title>
      <v-card-text>{{ dialogText }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="red--text text--accent-4"
          text="text"
          @click.native="$emit('onCancel')"
        >Annuler</v-btn>
        <v-btn
          class="blue--text text--accent-4"
          text="text"
          @click.native="$emit('onConfirm')"
        >Confirmer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
    props: ['dialog', 'dialogText', 'dialogTitle'],
    data() {
        return {};
    },
}
</script>
