import axios from "axios";
import { BASE_URL } from "../../Constants";
import { bus } from '../../main';
const state = {
    organismeParams: {
        organismName: null,
        organismAdress: null,
        phoneNumber: null,
        fixNumber: null,
        faxNumber: null,
        website: null,
        wilayaOrganismeId: null
    },
    organismeParamsToReset: {
        organismName: null,
        organismAdress: null,
        phoneNumber: null,
        fixNumber: null,
        faxNumber: null,
        website: null,
        wilayaOrganismeId: null
    },
    totalOrganismeCount: 0,
    organismes: [],
    organisme: {
        "organismName": "",
        "organismAdress": "",
        "phoneNumber": "",
        "fixNumber": "",
        "faxNumber": "",
        "website": "",
        "wilayaOrganismeId": null
    },
    organismeToReset: {
        "organismName": "",
        "organismAdress": "",
        "phoneNumber": "",
        "fixNumber": "",
        "faxNumber": "",
        "website": "",
        "wilayaOrganismeId": null
    }
}
const getters = {
    oneOrganisme: (state) => state.organisme,
    allOrganismes: (state) => state.organismes,
    getOrganismeParams: (state) =>state.organismeParams,
    getOrganismeTotalCount: (state) => state.totalOrganismeCount,
}

const actions = {
    async fetchAllOrganismes({getters, commit}, ar) {
        let params = {...getters.getOrganismeParams} 
        if(params.organismName != null) {
            params.search = params.organismName
        }
        await axios.get(`${BASE_URL}/organismes?page=${ar.page - 1}&size=20&sort=id,desc`
            , { headers: { Authorization: ar.token },params: params }).then(res =>{
                commit('setOrganismes', res.data);
                commit('setTotalCount', res.headers['x-total-count'])
            }
            ).catch(err=>{
                console.log(err)
            });
    },

    async addOrganisme({ commit }, ar) {
         await axios.post(`${BASE_URL}/organismes`,ar.organisme
            , { headers: { Authorization: ar.token } }).then(res =>{
                console.log(res.data);
                bus.$emit('successAdd', { bool: true, color: 'success', notice: "Nouveau organisme ajouté avec succès." })
                commit('addOrganisme', res.data);
            }).catch(err =>{
                console.log(err)
                bus.$emit('errorAdd', { bool: true, color: 'error', notice: "Echec Ajout organisme." })

            })
        
    },
    async deleteOrganisme({ commit }, ar) {
        await axios.delete(`${BASE_URL}/organismes/${ar.id}`
            , { headers: { Authorization: ar.token } }).then(res => {
                commit('removeOrganisme', ar.id);
                console.log(res);
                bus.$emit('successDelete', { bool: true, color: 'success', notice: "Operation faite avec succès." })
            })
            .catch(err => {
                console.log(err);
                bus.$emit('errorDelete', { bool: true, color: 'error', notice: "Echec operation! Veuillez réessayer ulterieurement." })
            })
    },
    async getOrganismeById({ commit }, ar) {
        await axios.get(`${BASE_URL}/organismes/${ar.id}` 
        ,{ headers: { Authorization: ar.token } })
        .then(res =>{
            console.log(res);
            commit('setOneOrganisme', res.data)
        }).catch(err=>{
            console.log(err)
        })

    },
    async editOrganisme({ commit }, ar) {
        await axios.put(BASE_URL+'/organismes/'+ ar.id, ar.organisme,
           { headers: {
                Authorization: ar.token, 'Accept': '*/*',
            }
        })
            .then(e => {
                commit('addMarchee', e.data)
                bus.$emit('successAdd', { bool: true, color: 'success', notice: "Nouveau marchée modifié avec succès." })
            })
            .catch(err => {
                console.log(err);
                bus.$emit('errorAdd', { bool: true, color: 'error', notice: "Echec modification marchée." })
            })
    },
    clearOrganisme({commit}) {
        commit('clearOrganisme', state.organismeToReset);
    },
    async clearOrganismeFilters({commit})  {
        commit('clearOrganismeFilters', state.organismeParamsToReset);
    },
}

const mutations = {
    setOrganismes: (state, organismes) => state.organismes = organismes,
    addOrganisme: (state, organisme) => state.organismes.push(organisme),
    removeMarchee: (state, id) => (state.organismes = state.organismes.filter(organisme => organisme.id !== id)),
    setOneOrganisme: (state, organisme) => state.organisme = organisme,
    clearOrganisme: (state, organisme) => state.organisme = organisme,
    setTotalCount: (state, count) => state.totalOrganismeCount = parseInt(count, 10),
    
}


export default {
    state,
    getters,
    actions,
    mutations
}