<template>
  <v-container fluid>
    <v-flex xs12>
      <v-card class="elevation-0">
        <v-card-title class="title">
          {{ title }}
          <v-spacer></v-spacer>
          <v-btn elevation="4" fab small dark class="grey mr-2" @click.native="cancel()">
            <v-icon dark="">mdi-close-circle-outline</v-icon>
          </v-btn>
          &nbsp;
          <v-btn elevation="4" fab small dark class="blue" @click.native="save()" :loading="loading" :disabled="loading">
            <v-icon>mdi-content-save-all</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div v-if="loading" class="custom-loader">
            <v-progress-circular
              indeterminate
              size="64"
              color="primary"
              class="mb-3"
            ></v-progress-circular>
            <div class="loading-text mt-3">{{ loadingText }}</div>
          </div>
          <v-container fluid grid-list-sm>
            <v-layout row wrap>
              <v-flex md3 sm12></v-flex>
              <v-flex md6 sm12>
                <v-container fluid grid-list-sm>
                  <v-layout row wrap>
                    <v-flex md12 sm12 xs12 class="mx-1 px-0">
                      <v-text-field
                        name="organismName"
                        label="Nom organisme *"
                        hint="Nom is required"
                        v-model="oneOrganisme.organismName"
                        class="input-group--focused"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12 sm12 class="mx-1 px-0">
                      <v-text-field
                        name="organismAdress"
                        label="adresse organisme"
                        hint="Address is required"
                        v-model="oneOrganisme.organismAdress"
                        class="input-group--focused"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12 sm12 class="mx-1 px-0">
                      <v-text-field
                        name="phoneNumber"
                        label="numéro de tel"
                        hint="Phone number is required"
                        v-model="oneOrganisme.phoneNumber"
                        class="input-group--focused"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12 sm12 class="mx-1 px-0">
                      <v-text-field
                        name="fixNumber"
                        label="numéro du fix"
                        hint="Fix number is required"
                        v-model="oneOrganisme.fixNumber"
                        class="input-group--focused"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12 sm12 class="mx-1 px-0">
                      <v-text-field
                        name="faxNumber"
                        label="numéro du fax"
                        hint="Fax number is required"
                        v-model="oneOrganisme.faxNumber"
                        class="input-group--focused"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12 sm12 xs12 class="mx-1 px-0">
                      <v-select
                        :items="wilayas"
                        item-text="name"
                        item-value="id"
                        label="Wilaya *"
                        v-model="oneOrganisme.wilayaOrganismeId"
                      ></v-select>
                    </v-flex>
                    <v-flex md12 sm12 class="mx-1 px-0">
                      <v-text-field
                        name="website"
                        label="site web"
                        v-model="oneOrganisme.website"
                        class="input-group--focused"
                        required
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-flex>
              <v-flex md3 sm12></v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-container>
</template>

<script>
import {mapActions,mapGetters} from 'vuex'
import wilaya from '../Wilaya';
import { handleFrontEndValidationError } from '../utils/responseHandler';

export default {
   data() {
       return {
           wilayas: wilaya,
           items:['occupe','libre'],
           title:'',
           bool: false,
           color: '',
           notice: '',
           loading: false,
           loadingText: 'Enregistrement en cours...'
       }
   },
   computed: mapGetters(['oneOrganisme','getUser','getToken']),
   methods:{
       ...mapActions(['addOrganisme','getOrganismeById','whoAMI','fetchAllOrganismes', 'editOrganisme', 'clearOrganisme']),
       cancel(){
         this.clearOrganisme();
         this.$router.push({name:'Organismes'});
       },
       async save(){
            this.loading = true;
            this.loadingText = this.$route.params.id ? 'Mise à jour en cours...' : 'Enregistrement en cours...';
            const validation = this.validateOrganisme(this.oneOrganisme);
            if (validation !== true) {this.loading = false; return;}
            let ar2 = {id: this.$route.params.id, organisme: this.oneOrganisme, token: this.getToken}
            try {
              if(this.$route.params.id){
                await this.editOrganisme(ar2);
              } else {
                await this.addOrganisme(ar2);
              }
            } catch (error) {
                console.error("Erreur lors de la sauvegarde :", error);
            } finally {
                this.loading = false;
            }
       },

       validateOrganisme(oneOrganisme){
          if (!oneOrganisme.organismName) {
            return handleFrontEndValidationError("Le nom est obligatoire");
          }
          if (!oneOrganisme.wilayaOrganismeId) {
            return handleFrontEndValidationError("La wilaya est obligatoire");
          }
          return true;
      }

   },
  mounted() {
    if (this.$route.params.id) {
      this.title = "Modifier Organisme";
    } else {
      this.title = "Nouveau Organisme";
    }
  },
  created(){
    if(this.$route.params.id){
      this.loading = true;
      this.loadingText = 'Chargement des données...';
      
      let ar = {id: this.$route.params.id, token: this.getToken}
      this.getOrganismeById(ar).then(() => {
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    }
  }
}
</script>

<style scoped>
.custom-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.85);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.loading-text {
  color: #1976D2;
  font-weight: 500;
  font-size: 16px;
}
</style>