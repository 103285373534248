import axios from "axios";
import { BASE_URL } from "../../Constants";
import { handleError, handleResponse } from "../../utils/responseHandler";
const state = {
    marcheeParams: {
        journal: null,
        wilayaIds: null,
        sectorIds: null,
        search: null,
        equalTodateEcheance: null,
        equalToDatePublication: null,
        tenderTypeAttachmentIds: null
    },
    marcheeParamsToReset: {
        journalParution: null,
        wilayaIds: null,
        sectorIds: null,
        search: null,
        equalTodateEcheance: null,
        equalToDatePublication: null,
        tenderTypeAttachmentIds: null
    },
    notifSent: false,
    totalMarcheeCount: 0,
    marchees: [],
    images: [],
    marchee: {
        "title": "",
        "arabicTitle": "",
        "journalParution": "",
        "anep": "",
        "lieuRetraitCDC": "",
        "montantCDC": null,
        "dateParution": "",
        "dateEcheance": "",
        "wilayaMarcheeId": null,
        "secteurIds": null,
        "organismeId": null,
        "tenderResultId": null,
        "tendersTypeId": null,
        "tendersTypeAttachmentId": null
    },
    marcheeToReset: {
        "title": "",
        "arabicTitle": "",
        "journalParution": "",
        "anep": "",
        "lieuRetraitCDC": "",
        "montantCDC": null,
        "dateParution": "",
        "dateEcheance": "",
        "wilayaMarcheeId": null,
        "secteurId": null,
        "organismeId": null,
        "tenderResultId": null,
        "tendersTypeId": null,
        "tendersTypeAttachmentId": null
    }
}
const getters = {
    oneMarchee: (state) => state.marchee,
    allMarchees: (state) => state.marchees,
    getMarcheeTotalCount: (state) => state.totalMarcheeCount,
    getMarcheeParams: (state) =>state.marcheeParams,
    getImages: (state) => state.images,
}

const actions = {
    async fetchAllMarchees({getters, commit}, ar) {
        let params = {...getters.getMarcheeParams}
        if(params.wilayaIds != null) {
            params.wilayaIds= params.wilayaIds.join(',')
        }
        if(params.sectorIds != null) {
            params.sectorIds= params.sectorIds.join(',')
        }
        if(params.tendersTypeAttachmentIds != null) {
            params.tendersTypeAttachmentIds= params.tendersTypeAttachmentIds.join(',')
        }
        if(params.dateParution != null) {
           params.equalToDatePublication = params.dateParution 
        }
        if(params.dateEcheance != null) {
           params.equalTodateEcheance = params.dateEcheance 
        }       
        console.log(getters.getMarcheeParams)
        const response = await axios.get(`${BASE_URL}/marchees?page=${ar.page - 1}&size=20&sort=id,desc`
            , { headers: { Authorization: ar.token },params: params });
        commit('setMarchees', response.data);
        commit('setTotalCount', response.headers['x-total-count'])
    },

    async setTendersTypeAttachmentId({ commit }, id) {
        commit('setTendersAttachmentId', id)
    },

    async addMarchee({ commit }, ar) {
        await axios.post(BASE_URL+'/marchees', ar.data, {
            headers: {
                Authorization: ar.token, 'Accept': '*/*',
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(e => {
                commit('addMarchee', e.data)
                return handleResponse(e, "Marchee ajouté avec succès");
            })
            .catch(e => {
                console.log(e);
                return handleError(e, "Echec ajout marchee");
            })
    },

    async deleteMarchee({ commit }, ar) {
        await axios.delete(`${BASE_URL}/marchees/${ar.id}`
            , { headers: { Authorization: ar.token } }).then(res => {
                commit('removeMarchee', ar.id);
                console.log(res);
                return handleResponse(res, "Marchee supprimée avec succès");
            })
            .catch(err => {
                console.log(err);
                return handleError(err, "Echec operation! Veuillez réessayer ulterieurement.");
            })
    },

    async addImage({ commit }, ar) {
        await axios.post(BASE_URL+'/images', ar.img, { headers: { Authorization: ar.token } })
            .then(res => {
                commit('addMarchee', res.data)
            })
    },

    async getMarcheeById({ commit }, ar) {
        await axios.get(`${BASE_URL}/marchees/${ar.id}` 
        ,{ headers: { Authorization: ar.token } })
        .then(res =>{
            console.log(res);
            commit('setOneMarchee', res.data)
        }).catch(err=>{
            console.log(err)
        })

    },

    async editMarchee({ commit }, ar) {
        await axios.post(BASE_URL+'/marchees/'+ ar.id, ar.data,
           { headers: {
                Authorization: ar.token, 'Accept': '*/*',
                'Content-Type': 'multipart/form-data'
            },
            params: {updatedAttachment: ar.bool}
        })
            .then(e => {
                commit('addMarchee', e.data)
                return handleResponse(e, "Marchee modifiée avec succès");
            })
            .catch(e => {
                console.log(e);
                return handleError(e, "Echec modification marchee.");
            })
    },

    async clearMarchee({ commit }) {
        commit('clearMarchee');
    },
    
    async clearMarcheeFilters({commit})  {
        commit('clearMarcheeFilters', state.marcheeParamsToReset);
    },

    async sendNotifs({ commit }, ar) {
        axios.post(BASE_URL + '/notifs', ar.data, { headers: { Authorization: ar.token } })
            .then(res => {
                console.log(res);
                return handleResponse(res, "Notifications envoyées avec succès");
            })
            .catch(err => {
                console.error(err);
                return handleError(err, "Échec envoi notifications");
            })
            .finally(() => {
                commit('setNotifSent', true);
            });
    },
    
    async fetchImages({commit}, ar) {
        await axios.get(BASE_URL+'/images',
        {headers: {Authorization: ar.token}, params: {"marcheeId": ar.id}}
       ).then((res)=>{
        commit('setImages',res.data)
        }).catch(e => console.log(e));
      },
      addImagesToDisplay({commit}, image) {
        commit('addImage', image)
      },
      removeOneImage({commit}, index) {
        commit('removeImage', index)
      }
}

const mutations = {
    setMarchees: (state, marchees) => state.marchees = marchees,
    setTendersAttachmentId: (state, id) => state.marchee.tendersTypeAttachmentId = id,
    addMarchee: (state, newMarchee) => state.marchees.push(newMarchee),
    removeMarchee: (state, id) => (state.marchees = state.marchees.filter(marchee => marchee.id !== id)),
    setOneMarchee: (state, marchee) => (state.marchee = marchee),
    setTotalCount: (state, count) => state.totalMarcheeCount = parseInt(count, 10) ,
    setNotifSent: (state, bool) => state.notifSent = bool,
    clearMarcheeFilters: (state, marcheeParams) =>state.marcheeParams = marcheeParams,
    setImages: (state, images) => state.images = images,
    addImage: (state, image) => state.images.push(image),
    removeImage:(state, index) => state.images.splice(index, 1),
    clearMarchee: (state) => {
        state.marchee = { 
            title: '',
            arabicTitle: '',
            journalParution: '',
            anep: '',
            lieuRetraitCDC: '',
            montantCDC: null,
            dateParution: '',
            dateEcheance: '',
            wilayaMarcheeId: null,
            organismeId: null,
            secteurIds: [],
            tendersTypeAttachmentId: null,
            watermarked: true,
            images: []
        };
        state.images = [];
    }
    
}


export default {
    state,
    getters,
    actions,
    mutations
}