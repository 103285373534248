import axios from "axios";
import { BASE_URL } from "../../Constants";
import { bus } from '../../main';
import { handleError, handleResponse } from "../../utils/responseHandler";
const state = {
    userParams: {
        name: null,
        email: null,
        number: null,
        sectorIds: null,
        debutAbonnement: null,
        finAbonnement: null,
        subscriptionStatus: null
    },
    userParamsToReset: {
        debutAbonnement: null,
        finAbonnement: null,
        name: null,
        email: null,
        number: null,
        sectorIds: null,
        subscriptionStatus: null
    },
    dailyRecapSentStatus: false,
    totalUserCount: 0,
    users: [],
    oneUser: {
        loginUser: '',
        passwordUser: '',
        firstName: '',
        lastName: '',
        email: '',
        userExtraDTO: {
            username: '',
            nom: '',
            prenom: '',
            numeroTel: '',
            email: '',
            typeAbonnement: '',
            debutAbonnement: '',
            finAbonnement: '',
            secteurs: ''
        }
    },
    userToUpdate: null,
    
}
const getters = {
    getAllUsers: (state) => state.users,
    oneUser: (state) => state.oneUser,
    getUserTotalCount: (state) => state.totalUserCount,
    getOneUserExtra: (state) => state.userToUpdate,
    getUserParams: (state) => state.userParams,
}

const actions = {
    async fetchAllUsers({getters, commit }, ar) {
        let params = null;
        params ={...getters.getUserParams}
        if(params.sectorIds != null) {
            params.sectorIds= params.sectorIds.join(',')
        }
        console.log(params)
        if(params.subscriptionStatus != null) {
            params.subscriptionStatus= params.subscriptionStatus.join(',')
        }    
        const response = await axios.get(`${BASE_URL}/user-extras?page=${ar.page - 1}&size=5&sort=id,desc`
            , { headers: { Authorization: ar.token }, params: params });
        commit('setUsers', response.data);
        commit('setTotalCount', response.headers['x-total-count'])
    },

    async activateUser({ commit }, ar) {
        await axios.get(`${BASE_URL}/activate?key=${ar.key}`
            , { headers: { Authorization: ar.token } }).then(res=>{
                commit('setUsers', state.users);
                return handleResponse(res, "Utilisateur activé avec succès.");
            }).catch(err=>{
                console.log(err);
                return handleError(err, "Echec activation! Veuillez réessayer ulterieurement.");
            })
    },

    async sendDailyEmailRecap({commit},ar) {
        await axios.get(`${BASE_URL}/runJob?selectedDate=${ar.date}`
            , { headers: { Authorization: ar.token } }).then(res=>{
                commit('setDailyRecapSent', true)
                return handleResponse(res, "Recap du jour envoyé avec succès.");
            }).catch(err=>{
                console.log(err);
                commit('setDailyRecapSent', false)
                return handleError(err, "Echec envoie recap du jour! Veuillez réessayer ulterieurement.");
            })
    },

    async sendDailyEmailRecap2({ commit }, ar) {
        try {
            bus.$emit('pendingRecapSend', { showLoader: true, loaderMessage: 'Envoie du recap en cours...', color: 'blue' });

            const response = await axios.get(`${BASE_URL}/runJob?selectedDate=${ar.date}`, {
                headers: { Authorization: ar.token }
            });
    
            // Check the job status periodically
            await checkJobStatus(response.data, ar);
            // Job completed successfully, hide loader or show success message
            commit('setDailyRecapSent', true);
            return handleResponse(response, "Recap du jour envoyé avec succès.");
        } catch (error) {
          // Handle errors, hide loader, and show error message
          commit('setDailyRecapSent', false);
          return handleError(error, "Echec envoie recap du jour! Veuillez réessayer ultérieurement.");
        }
    },

    async getUserExtraById({commit}, ar) {
        await axios.get(`${BASE_URL}/user-extras/${ar.id}`
        ,{ headers: { Authorization: ar.token } }
        ).then(res=> {
            console.log(res);
            commit('setUserToUpdate', res.data);
        }).catch(err=>{
            console.log(err)
        })
    },

    async deactivateUser({ commit }, ar) {
         await axios.put(`${BASE_URL}/deactivate/${ar.id}`, {}
            , { headers: { Authorization: ar.token } }).then(res=>{
                console.log(res);
                commit('setUsers', state.users);
                return handleResponse(res, "Utilisateur desactivé avec succès.");
            }).catch(err=>{
                console.log(err);
                return handleError(err, "Echec desactivation! Veuillez réessayer ulterieurement.");
           })
    },

    async saveUser({commit}, ar) {
        let user = ar.user;
        console.log(user)
        console.log('Selected role:', user.role);
        let userToSave = {
            login: user.loginUser,
            password: user.passwordUser,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            authorities: user.role ? [user.role] : ['ROLE_USER'],
            userExtraDTO: {
                username: user.loginUser,
                nom: user.firstName,
                prenom: user.lastName,
                numeroTel: user.userExtraDTO.numeroTel,
                email: user.email,
                typeAbonnement: user.userExtraDTO.typeAbonnement,
                debutAbonnement: '',
                finAbonnement: '',
                secteurs: user.userExtraDTO.secteurs.join(',')
            }
        }
        await axios.post(BASE_URL+'/register', userToSave
        ,{ headers: { Authorization: ar.token } } )
        .then((res)=>{
            commit('addUser', res.data) 
            return handleResponse(res, "Utilisateur ajouté avec succès.");
        }).catch(e =>{
            console.log(e);
            return handleError(e, "Echec Ajout utilisateur.");
        })
    },

    async updateUser({commit}, ar) {
        let user = ar.user;
        let userToUpdate =  {
            "id": user.userId,
            "login": user.username,
            "firstName": user.nom,
            "lastName": user.prenom,
            "email": user.userEmail,
            "imageUrl": "",
            "langKey": "fr",
            "userExtraDTO": {
                "id": user.id,
                "finAbonnement": user.finAbonnement,
                "numeroTel": user.numeroTel,
                "subscriptionStatus": user.subscriptionStatus
            },
            "sectorIds": user.secteurIds
        }
        await axios.put(BASE_URL+'/user-info', userToUpdate
        ,{ headers: { Authorization: ar.token } } )
        .then((res)=>{
            commit('addUser', res.data)
            return handleResponse(res, "Utilisateur modifié avec succès.");
        }).catch(e =>{
            console.log(e);
            return handleError(e, "Echec Modification utilisateur.");
        })
    },

    async deleteUser({ commit }, ar) {
        await axios.delete(`${BASE_URL}/admin/users/${ar.login}`
            , { headers: { Authorization: ar.token } }).then(res => {
                commit('removeUser', ar.login);
                console.log(res);
                return handleResponse(res, "Utilisateur supprimé avec succès.");
            })
            .catch(err => {
                console.log(err);
                return handleError(err, "Echec operation! Veuillez réessayer ulterieurement.");
            })
    },

    async clearUserFilters({commit})  {
       await commit('clearUserFilters');
    },

    async exportCsvUsers({ getters }, ar) {
        let params = { ...getters.getUserParams };
        if (params.sectorIds != null) {
            params.sectorIds = params.sectorIds.join(',');
        }
        if(params.subscriptionStatus != null) {
            params.subscriptionStatus= params.subscriptionStatus.join(',')
        }   
        try {
            const response = await axios.get(`${BASE_URL}/export-csv/user-extras`, {
                headers: { Authorization: ar.token },
                params: params,
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data], { type: "text/csv" }));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "export_csv.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Erreur lors de l'exportation du CSV :", error);
        }
    }

}

const mutations = {
    setUsers: (state, users) => state.users = users,
    setOneUser: (state, user) => state.oneUser = user,
    addUser: (state, user) => state.users.push(user),
    setTotalCount: (state, count) => state.totalUserCount = parseInt(count, 10),
    setUserToUpdate: (state, userExtra) => state.userToUpdate = userExtra,
    removeUser: (state, login) => (state.users = state.users.filter(user => user.username !== login)),
    // clearUserFilters: (state) => state.userParams = state.userParamsToReset,
    setDailyRecapSent: (state)=>state.dailyRecapSentStatus = state.status,
    clearUserFilters: (state) => {
        state.userParams = { 
            debutAbonnement: null,
            finAbonnement: null,
            name: null,
            email: null,
            number: null,
            sectorIds: null,
            subscriptionStatus: null
        };
    }

}
async function checkJobStatus(jobId, ar) {
    let jobCompleted = false;
    while (!jobCompleted) {
      const response = await axios.get(`${BASE_URL}/jobStatus/${jobId}`, {
        headers: { Authorization: ar.token}
      });
  
      if (response.data === 'COMPLETED') {
        // Job completed, exit the loop
        jobCompleted = true;
      } else if (response.data === 'FAILED' || response.data === 'UNKNOWN') {
        // Handle failure or unknown status, exit the loop
        throw new Error(`Job status: ${response.data}`);
      }
  
      // Add a delay before checking the status again
      await new Promise(resolve => setTimeout(resolve, 2000));
    }
  }
  


export default {
    state,
    getters,
    actions,
    mutations
}