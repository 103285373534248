<template>
   <v-container fluid>
        <v-flex xs12>
      <v-card>
        <v-card-title>
          <span class="title">
            Organismes ({{getOrganismeTotalCount}})
          </span>
          <v-spacer></v-spacer>
          <table-header-buttons :search="search" :add="add"></table-header-buttons>
        </v-card-title>
        <tabledata
        :expand="false"
        :headers="headers" 
        :items="formattedOrganismes"
        @edit="edit"
        @remove="remove"
        :page.sync="page"></tabledata>
        <div class="text-center pt-2">
        <v-pagination
        v-model="page"
        :length="this.pagesLength(getOrganismeTotalCount)"
        :total-visible="this.pagesLength(getOrganismeTotalCount) < 20 ? this.pagesLength(getOrganismeTotalCount) : this.pagesLength(getOrganismeTotalCount)"
      ></v-pagination>
        </div>
      </v-card>
    </v-flex>
    <search-panel :rightDrawer="rightDrawer" @cancelSearch="cancelSearch" @searchData="searchData">
      <v-container fluid class="pa-3">
        <v-row>
          <v-col cols="12">
            <v-text-field label="Organisme name" light v-model="getOrganismeParams.organismName"></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </search-panel>
    <confirm-dialog
      :dialog="dialog"
      :dialogTitle="dialogTitle"
      :dialogText="dialogText"
      @onConfirm="onConfirm"
      @onCancel="onCancel"
    />
    <v-snackbar
      
      :right="true"
      :color="color"
      v-model="bool"
      
    >
      {{ notice }}
      <v-btn dark text @click.native="closeSnackbar">Close</v-btn>
    </v-snackbar>
    </v-container>
</template>

<script>
import TableData from '../components/TableData.vue'
import TableHeaderButtons from '../components/TableHeaderButtons.vue'
import ConfirmDialogVue from '../components/ConfirmDialog.vue';
import {mapGetters,mapActions} from 'vuex';
import {bus} from '../main'
import { getRoleFromToken } from '../utils/jwtUtils';
import { formatDate } from '../utils/dateFormatter';
import SearchPanel from '../components/SearchPanel.vue';
export default {
name: 'Organismes',
components: {
    'tabledata': TableData,
    'table-header-buttons':TableHeaderButtons,
    'confirm-dialog':ConfirmDialogVue,
    'search-panel': SearchPanel,
},
data() {
    return {
        role: getRoleFromToken() || 'ROLE_REDACTOR',
        allHeaders:[
                {text:"id",value:"id",sortable:true},
                {text:"organismName",value:"organismName",sortable:true},
                {text:"organismAdress",value:"organismAdress",sortable:true},
                {text:"phoneNumber",value:"phoneNumber",sortable:false},
                {text:"fixNumber",value:"fixNumber",sortable:false},
                {text:"faxNumber",value:"faxNumber",sortable:false},
                {text:"website",value:"website",sortable:false},
                {text: "Créé le", value: "created", sortable: true, roles: ["ROLE_ADMIN"] },
                {text: "Créé par", value: "createdBy", sortable: true, roles: ["ROLE_ADMIN"] },
                {text: "Modifié le", value: "updated", sortable: true, roles: ["ROLE_ADMIN"] },
                {text: "Modifié par", value: "updatedBy", sortable: true, roles: ["ROLE_ADMIN"] },
                {text:"",value:"actions",sortable:false},
            ],
            bool:false,color:'',notice:'',itemId:'',dialog:false,
            dialogTitle:'Organism delete dialog',
            dialogText:'Do you want to delete this organism ?',
            page: 1,
            rightDrawer: false,
    }
},
watch: {
    page: function(){
      let ar = { token: this.getToken, page: this.page };
      this.fetchAllOrganismes(ar);
    }
  },
computed: {
    headers() {
      return this.allHeaders.filter(col => {
        return !col.roles || col.roles.includes(this.role);
      });
    },
    ...mapGetters(['allOrganismes','getUser','getToken', 'getOrganismeTotalCount', 'getOrganismeParams', 'clearOrganismeFilters']),
    formattedOrganismes() {
      return this.allOrganismes.map(organisme => {
        const formattedOrganisme = {};
        Object.keys(organisme).forEach(key => {
          const value = organisme[key];
          if (key === 'created' || key === 'updated') {
            formattedOrganisme[key] = value ? formatDate(value) : '/';
          }
          else {
            formattedOrganisme[key] = value !== null && value !== undefined && value !== '' ? value : '/';
          }
        });
        return formattedOrganisme;
      });
    }
},  
methods:{
    ...mapActions(['fetchAllOrganismes', 'whoAMI', 'deleteOrganisme']),
    add(){
        this.$router.push({name:'OrganismeForm'});
    },
    edit(item){
        console.log(item);
        this.$router.push(`/organismes/${item.id}`)
    },
    remove(item){
        this.itemId=item.id;
        this.dialog=true;
        
    },
    onConfirm(){
        let ar={id:this.itemId,token:this.getToken}
        this.deleteOrganisme(ar);
        this.dialog=false;
    },
    onCancel(){
        this.dialog=false;
        this.itemId='';
    },
    setSnackbar(obj){
        this.bool=obj.bool;
        this.notice=obj.notice;
        this.color=obj.color;
    },
    closeSnackbar(){
        this.bool= !this.bool;
    },
    pagesLength(n){
      return n <= 20 ? 1 : n <= 40 ? 2 : Math.floor(n/20) + 1 
    },
    search() {
      this.rightDrawer = !this.rightDrawer;
    },
    cancelSearch() {
      this.clearOrganismeFilters();
      let ar = { token: this.getToken, page: this.page };
      this.fetchAllOrganismes(ar);
      this.rightDrawer = false;
    },
    searchData() {
      this.rightDrawer = false;
      let ar = { token: this.getToken, page: this.page };
      this.fetchAllOrganismes(ar);
    }
},
created() {
    this.whoAMI();
    if(this.getUser != null) {
        let ar = {token:this.getToken, page: this.page}
        this.fetchAllOrganismes(ar);
    }
     bus.$on('successDelete', e => {
      console.log('ikechmed ar emit ayini n delete');
      this.setSnackbar(e);
    });
    bus.$on('errorDelete', e => {
      console.log('ikechmed ar emit ayini n error delete');
      this.setSnackbar(e);
    });

}
}
</script>

<style>

</style>