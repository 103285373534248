import axios from "axios"
import { BASE_URL } from "../../Constants"
import { handleError, handleResponse } from "../../utils/responseHandler"
const state={
    logged:false,
    user:null,
    token:null,
    users:[],
    selectedUser:{id:'',login:'',password:'',idHotel:'',admin:''},
    userReset:{id:'',login:'',password:'',idHotel:'',admin:''},
}
const getters={
signedIn:(state)=>state.logged,
getUser:(state)=>state.user,
getToken:(state)=>state.token,
getUsers:(state)=>state.users,
getSelectedUser:(state)=>state.selectedUser,
isAdmin:(state)=>state.user.auth.split(',').includes('ROLE_ADMIN'),
isSales:(state)=>state.user.auth.split(',').includes('ROLE_SALES'),

}
const actions={
    async login({ commit }, user) {
        try {
            const res = await axios.post(BASE_URL + '/authenticate', user);
            let token = res.headers.authorization;
            commit('setLogged', true);
            localStorage.setItem('token', token);
            if (token.startsWith('Bearer ')) {
                token = token.slice(7);
                commit('setUser', JSON.parse(atob(token.split(".")[1])));
            }
            return handleResponse(res, "Connexion réussie");
        } catch (e) {
            return handleError(e, "Échec de la connexion");
        }
    },
    
    async whoAMI({commit}){
        let tok=localStorage.getItem("token");
        if(tok && tok.startsWith('Bearer ')){
            commit('setAuthorizationHeader',tok);
            tok = tok.slice(7, tok.length)
            var toks = tok.split(".");
            commit('setUser',JSON.parse(atob(toks[1])));
            commit('setLogged',true);
        }else{
            commit('setUser',null);
            commit('setLogged',false);
            commit('setAuthorizationHeader',null);        
        }
    },

    async logout({ commit }) {
        try {
            // let token = localStorage.getItem('token');
            // await axios.post(BASE_URL + '/logout', {}, {
            //     headers: { Authorization: `${token}` }
            // });
            localStorage.removeItem('token');
            commit('setUser', null);
            commit('setLogged', false);
            commit('setAuthorizationHeader', null);
            // this.$router.push("/login");
        } catch (error) {
            console.error("Erreur lors de la déconnexion :", error);
        }
    },

    async auth({commit}, ar) {
        let tok=localStorage.getItem("token");
        if(tok && tok.startsWith('Bearer ')) {
            await axios.get(BASE_URL+'/account',{headers:{Authorization:ar.token}})
            .then(response =>{
                console.log(response);
                commit('setLogged',true);
            })
            .catch(err => {
                console.log(err);
                commit('setLogged',false);
            })
        }
    },

}
const mutations={
    setUser:(state,user)=>(state.user=user),
    setLogged:(state,bool)=>(state.logged=bool),
    setAuthorizationHeader:(state,tok)=>(state.token=tok),
    setUsers:(state,users)=>(state.users=users),
    setSelectedUser:(state,user)=>(state.selectedUser=user),
    removeUser:(state,id)=>(state.users=state.users.filter(user=>user.id!==id)),
    newUser:(state,user)=>(state.users.push(user)),
    reseThetUser:(state)=>(state.selectedUser=state.userReset),
    setUserinfos:(state,idhotel)=>(state.selectedUser.idHotel=idhotel),
    setAdmin:(state,bool)=>state.selectedUser.admin=bool,

}

export default{
    state,
    getters,
    actions,
    mutations
}