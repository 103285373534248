<template>
  <div>
    <v-btn elevation="4" class="blue-grey mr-2" fab small dark @click.native="search">
      <v-icon>mdi-magnify</v-icon>
    </v-btn>
    <v-btn elevation="4" class="brown lighten-1 mr-2" fab small dark>
      <v-icon>mdi-refresh</v-icon>
    </v-btn>
    <v-btn v-if="isAdmin" elevation="4" class="teal darken-2 mr-2" fab small dark @click.native="send">
      <v-icon>mdi-email</v-icon>
    </v-btn>
    <v-btn  v-if="isAdmin" elevation="4" class="green darken-2 mr-2" fab small dark @click.native="$emit('export')">
      <v-icon>mdi-file-export</v-icon>
    </v-btn>
    <v-btn elevation="4" class="deep-orange darken-3" fab small dark @click.native="add">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    isAdmin: Boolean,
    add: Function,
    reloadData: Function,
    print: Function,
    search: Function,
    send: Function
  },
  emits: ['export']
}
</script>