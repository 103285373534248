<template>
  <v-container fluid>
    <v-flex xs12>
      <v-card>
        <v-card-title>
          <span class="title">Utilisateurs ({{ getUserTotalCount }}) </span>
          <v-spacer></v-spacer>
          <table-header-buttons 
            :isAdmin="isAdmin" 
            :add="add" 
            :search="search" 
            :send="openDialogTosendDailyRecap"
            @export="exportData"
          ></table-header-buttons>  
        </v-card-title>
        <div v-if="loading"  class="custom-loader">
            <v-progress-circular
              indeterminate
              size="64"
              color="primary"
              class="mb-3"
            ></v-progress-circular>
            <div class="loading-text mt-3">{{ loadingText }}</div>
          </div>
        <tabledata 
        :headers="headers" 
        :items="formattedUsers" 
        @activate="activate" 
        :expand="true"         
        @edit="edit"
        @remove="remove"
        :isAdmin="isAdmin"
          ></tabledata>
        <div class="text-center pt-2">
        <v-pagination
        v-model="page"
        :length="this.pagesLength(getUserTotalCount)"
        :total-visible="30"
      ></v-pagination>
        </div>
      </v-card>
    </v-flex>

    <search-panel :rightDrawer="rightDrawer" @cancelSearch="cancelSearch" @searchData="searchData">
      <!-- Existing search fields -->
      <v-layout row>
        <v-flex xs11 offset-xs1>
          <v-text-field name="input-1-3" label="Numero Tél" light v-model="getUserParams.number"></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs11 offset-xs1>
          <v-text-field name="input-1-3" label="Nom ou Prenom" light v-model="getUserParams.name"></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs11 offset-xs1>
          <v-text-field name="input-1-3" label="Email" light v-model="getUserParams.email"></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs11 offset-xs1>
          <v-select :items="secteurs" label="Secteur" item-text="name" item-value="id" chips multiple v-model="getUserParams.sectorIds"> </v-select>
        </v-flex>
      </v-layout>
      
      <!-- Modification de la sélection pour permettre un choix multiple -->
      <v-layout row>
        <v-flex xs11 offset-xs1>
          <v-select
            :items="subscriptionStatuses"
            label="Statut Abonnement"
            item-text="text"
            item-value="value"
            chips
            multiple
            v-model="getUserParams.subscriptionStatus"
          ></v-select>
        </v-flex>
      </v-layout>
      
      <!-- New date picker for subscription start date -->
      <v-layout row>
        <v-flex xs11 offset-xs1>
          <v-menu 
            :close-on-content-click="true" 
            transition="v-scale-transition" 
            offset-y
          >
            <template v-slot:activator="{ on }">
              <v-text-field 
                v-on="on" 
                label="Date Début Abonnement" 
                prepend-icon="mdi-calendar" 
                v-model="getUserParams.debutAbonnement" 
                readonly
              ></v-text-field>
            </template>
            <v-date-picker 
              v-model="getUserParams.debutAbonnement" 
              no-title 
              scrollable
            ></v-date-picker>
          </v-menu>
        </v-flex>
      </v-layout>

      <!-- New date picker for subscription end date -->
      <v-layout row>
        <v-flex xs11 offset-xs1>
          <v-menu 
            :close-on-content-click="true" 
            transition="v-scale-transition" 
            offset-y
          >
            <template v-slot:activator="{ on }">
              <v-text-field 
                v-on="on" 
                label="Date Fin Abonnement" 
                prepend-icon="mdi-calendar" 
                v-model="getUserParams.finAbonnement" 
                readonly
              ></v-text-field>
            </template>
            <v-date-picker 
              v-model="getUserParams.finAbonnement" 
              no-title 
              scrollable
            ></v-date-picker>
          </v-menu>
        </v-flex>
      </v-layout>
    </search-panel>
    <confirm-dialog :dialog="dialog" :dialogTitle="dialogTitle" :dialogText="dialogText" @onConfirm="onConfirm" @onCancel="onCancel" />
    <calendar-dialog :calendarDialog="calendarDialog" :sendDaily="sendDaily" :showCalendar="showCalendar"></calendar-dialog>
  </v-container>
</template>
<script>
import TableData from '../components/TableData.vue';
import CalendarDialogVue from '../components/CalendarDialog.vue';
import { bus } from '../main';
import ConfirmDialogVue from '../components/ConfirmDialog.vue';
import SearchPanel from '../components/SearchPanel.vue';
import { mapGetters, mapActions } from 'vuex';
import SECTORS from '../Sectors';
import TableHeaderButtonsForUsersViewVue from '../components/TableHeaderButtonsForUsersView.vue';
import { getRoleFromToken } from '../utils/jwtUtils';
import { formatDate } from '../utils/dateFormatter';
export default {
  name: 'Utilisateur',
  components: { 
    tabledata: TableData, 
    'table-header-buttons': TableHeaderButtonsForUsersViewVue,
    'confirm-dialog': ConfirmDialogVue, 
    'search-panel': SearchPanel, 
    'calendar-dialog': CalendarDialogVue
    // 'loading-snackbar': LoadingSnackBarVue
  },

  created() {
    this.whoAMI();
    let ar = { token: this.getToken, page: this.page };
    this.fetchAllUsers(ar);
        bus.$on('errorSend', e => {
      this.setSnackbar(e);
    });
    bus.$on('pendingRecapSend', e => {
      this.showLoader = e.showLoader;
      this.loaderMessage = e.loaderMessage;
      this.loderSnackbarColor = e.color;
    });
    bus.$on('successRecapSend', e => {
      this.showLoader = e.showLoader;
      this.loaderMessage = e.loaderMessage;
      this.loderSnackbarColor = e.color;
    });

    bus.$on('errorRecapSend', e => {
      console.log("ERROR RECAP SEND")
      this.showLoader = e.showLoader;
      this.loaderMessage = e.loaderMessage;
      this.loderSnackbarColor = e.color;
    });
    bus.$on('selectedDate', date => {
      this.selectedDate=date;
    });
  },

  data() {
    return {
      role: getRoleFromToken() || 'ROLE_ADMIN',
      allHeaders: [
        { text: 'ID', left: true, value: 'userId' },
        { text: 'Login', value: 'username' },
        { text: 'Nom', value: 'nom' },
        { text: 'Prénom', value: 'prenom' },
        { text: 'Email', value: 'userEmail' },
        /*{ text: 'Télephone', value: 'numeroTel' },*/
        { text: 'Secteurs', value: 'secteurDTOS' },
        /*{ text: 'Type abonnement', value: 'typeAbonnement' },*/
        { text: 'Début Abonnement', value: 'debutAbonnement' },
        { text: 'Fin Abonnement', value: 'finAbonnement' },
        { text: 'Statut Abonnement', value: 'abonnementStatus', sortable: true, roles: ["ROLE_ADMIN"] },
        { text: 'Statut', value: 'activate', sortable: false, roles: ["ROLE_ADMIN"] },
        // { text: "Créé le", value: "created", sortable: true, roles: ["ROLE_ADMIN"] },
        // { text: "Créé par", value: "createdBy", sortable: true, roles: ["ROLE_ADMIN"] },
        // { text: "Modifié le", value: "updated", sortable: true, roles: ["ROLE_ADMIN"] },
        // { text: "Modifié par", value: "updatedBy", sortable: true, roles: ["ROLE_ADMIN"] },
        {text:"",value:"actions",sortable:false},
      ],
      subscriptionStatuses: [
        { text: 'Prospect', value: 'TRIAL' },
        { text: 'Client', value: 'ACTIVE' },
        { text: 'Expiré', value: 'EXPIRED' }
      ],
      bool: false,
      color: '',
      notice: '',
      itemId: '',
      login: '',
      dialog: false,
      dialogTitle: 'Confirmation',
      dialogText: 'Voulez-vous vraiment supprimer cet utilisateur ?',
      calendarDialog: false,
      page: 1,
      loading: false,
      loadingText: '...',
      rightDrawer: false,
      secteurs: SECTORS,
      selectedDate: null,
      showLoader: false,
      loaderMessage: '',
      loderSnackbarColor: ''
    };
  },
  watch: {
    page: function(){
      let ar = { token: this.getToken, page: this.page };
      this.fetchAllUsers(ar);
    }
  },
  computed: {
    headers() {
      return this.allHeaders.filter(col => {
        return !col.roles || col.roles.includes(this.role);
      });
    },
    ...mapGetters(['getAllUsers', 'getUser', 'getToken', 'getUserTotalCount', 'getUserParams', 'isAdmin']),
    // formattedUsers() {
    //   return this.getAllUsers.map(user => {
    //     const formattedUser = {};
    //     Object.keys(user).forEach(key => {
    //       const value = user[key];
    //       if (key === 'created' || key === 'updated') {
    //         formattedUser[key] = value ? formatDate(value) : '/';
    //       }
    //       else {
    //         formattedUser[key] = value !== null && value !== undefined && value !== '' ? value : '/';
    //       }
    //     });
    //     return formattedUser;
    //   });
    // }

    formattedUsers() {
      return this.getAllUsers.map(user => {
        const formattedUser = {};
        Object.keys(user).forEach(key => {
          const value = user[key];
          if (key === 'created' || key === 'updated') {
            formattedUser[key] = value ? formatDate(value) : '/';
          }
          else {
            formattedUser[key] = value !== null && value !== undefined && value !== '' ? value : '/';
          }
        });
        

        if (formattedUser.subscriptionStatus === 'TRIAL') {
          formattedUser.abonnementStatus = {
            text: 'Prospect',
            color: 'grey'
          };
        } else if (formattedUser.subscriptionStatus === 'ACTIVE') {
          formattedUser.abonnementStatus = {
            text: 'Client',
            color: 'green'
          };
        } else if (formattedUser.subscriptionStatus === 'CANCELED') {
          formattedUser.abonnementStatus = {
            text: 'Expiré',
            color: 'red'
          };
        }else if (formattedUser.subscriptionStatus === 'EXPIRED') {
          formattedUser.abonnementStatus = {
            text: 'Expiré',
            color: 'red'
          }
        }else{
          formattedUser.abonnementStatus = {
            text: 'Inconnu',
            color: 'blue'
          }
        }
        
        return formattedUser;
      });
    }
  },
  methods: {
    ...mapActions(['fetchAllUsers', 'activateUser', 'whoAMI', 'deactivateUser', 'deleteUser', 'clearUserFilters', 'sendDailyEmailRecap', 'sendDailyEmailRecap2', 'exportCsvUsers']),
    add() {
      console.log('ikechmed');
      this.$router.push('/newUtilisateur');
    },
    edit(item) {
      console.log(item);
      this.$router.push(`/users/${item.id}`);
    },
    remove(item) {
      this.login = item.username
      this.itemId = item.id;
      this.dialog = true;
    },
    activate(item) {
      console.log('activate' + item.activationKey);

      if (item.activationKey != null) {
        let ar = { key: item.activationKey, token: this.getToken };
        this.activateUser(ar).then(res => {
          console.log(res);
          let ar2 = { token: this.getToken, page: this.page};
          this.fetchAllUsers(ar2);
        });
      } else {
        let ar = { id: item.userId, token: this.getToken, page: this.page};
        this.deactivateUser(ar).then(res => {
          console.log(res);
          let ar2 = { token: this.getToken };
          this.fetchAllUsers(ar2);
        });
      }
    },
    reloadData() {
      console.log('reloadData');
    },
    showCalendar(){
      this.calendarDialog=!this.calendarDialog
    },
    async onConfirm() {
      this.dialog = false;
      this.loading = true;
      let ar = { login: this.login, token: this.getToken };
      this.deleteUser(ar).then(() => {
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },

    onCancel() {
      this.dialog = false;
      this.itemId = '';
    },
    print() {},
    pagesLength(n){
      return n <= 5 ? 1 : n <= 10 ? 2 : Math.floor(n/5) + 1 
    },
    search() {
      this.rightDrawer = !this.rightDrawer;
    },
    openDialogTosendDailyRecap() {
      console.log(' SendDailyRecap');
      this.calendarDialog= true;
      //let ar = { token: this.getToken, page: this.page };
      //this.sendDailyEmailRecap(ar);
    },
    sendDaily() {
      let ar = { token: this.getToken, date: this.selectedDate };
      this.sendDailyEmailRecap2(ar);
      this.calendarDialog= false;
    },
    cancelSearch() {
      this.clearUserFilters().then(()=>{
      let ar = { token: this.getToken, page: this.page };
      this.fetchAllUsers(ar);
      this.rightDrawer = false;
      })

    },
    searchData() {
      this.rightDrawer = false;
      let ar = { token: this.getToken, page: this.page };
      this.fetchAllUsers(ar);
    },
    async exportData() {
      console.log('exportData');
      let export_data = { token: this.getToken, page: this.page};
      this.exportCsvUsers(export_data);
    }
   
  },
};
</script>
<style scoped>
.custom-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.85);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.loading-text {
  color: #1976D2;
  font-weight: 500;
  font-size: 16px;
}
</style>