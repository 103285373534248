<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <span class="title">Marchés ({{ getMarcheeTotalCount }})</span>
            <v-spacer></v-spacer>
            <table-header-buttons :search="search" :add="add"></table-header-buttons>
          </v-card-title>

          <div v-if="loading"  class="custom-loader">
            <v-progress-circular
              indeterminate
              size="64"
              color="primary"
              class="mb-3"
            ></v-progress-circular>
            <div class="loading-text mt-3">{{ loadingText }}</div>
          </div>

          <tabledata 
            :expand="false" 
            :headers="headers" 
            :items="formattedMarchees" 
            @edit="edit" 
            @remove="remove" 
            @notify="notify" 
            :page.sync="page"
          ></tabledata>
          <div class="text-center pt-2">
            <v-pagination
              v-model="page"
              :length="pagesLength(getMarcheeTotalCount)"
              :total-visible="pagesLength(getMarcheeTotalCount) < 20 ? pagesLength(getMarcheeTotalCount) : 20"
            ></v-pagination>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <!-- Search Panel -->
    <search-panel :rightDrawer="rightDrawer" @cancelSearch="cancelSearch" @searchData="searchData">
      <v-container fluid class="pa-3">
        <v-row>
          <v-col cols="12">
            <v-text-field label="Titre ou ANEP" light v-model="getMarcheeParams.search"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-text-field label="Journal parution" light v-model="getMarcheeParams.journal"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-select :items="secteurs" label="Secteur" item-text="name" item-value="id" chips multiple v-model="getMarcheeParams.sectorIds"></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-select :items="wilayas" item-text="name" item-value="id" label="Wilaya" chips multiple v-model="getMarcheeParams.wilayaIds"></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-select multiple chips :items="types" label="Types" item-text="name" item-value="id" v-model="getMarcheeParams.tenderTypeAttachmentIds"></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-menu :close-on-content-click="true" transition="v-scale-transition" offset-y>
              <template v-slot:activator="{ on }">
                <v-text-field v-on="on" label="Date de parution" prepend-icon="mdi-calendar" v-model="getMarcheeParams.dateParution" readonly></v-text-field>
              </template>
              <v-date-picker v-model="getMarcheeParams.dateParution" no-title scrollable></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-menu :close-on-content-click="true" transition="v-scale-transition" offset-y>
              <template v-slot:activator="{ on }">
                <v-text-field v-on="on" label="Date d'échéance" prepend-icon="mdi-calendar" v-model="getMarcheeParams.dateEcheance" readonly></v-text-field>
              </template>
              <v-date-picker v-model="getMarcheeParams.dateEcheance" no-title scrollable></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-container>
    </search-panel>

    <confirm-dialog :dialog="dialog" :dialogTitle="dialogTitle" :dialogText="dialogText" @onConfirm="onConfirm" @onCancel="onCancel" />
  </v-container>
</template>

<script>
import TableData from '../components/TableData.vue';
import TableHeaderButtons from '../components/TableHeaderButtons.vue';
import ConfirmDialogVue from '../components/ConfirmDialog.vue';
import SearchPanel from '../components/SearchPanel.vue';
import { mapGetters, mapActions } from 'vuex';
import wilaya from '../Wilaya';
import SECTORS from '../Sectors';
import { getRoleFromToken } from '../utils/jwtUtils';
import { formatDate } from '../utils/dateFormatter';
export default {
  name: 'Marchees',
  components: {
    tabledata: TableData,
    'table-header-buttons': TableHeaderButtons,
    'confirm-dialog': ConfirmDialogVue,
    'search-panel': SearchPanel,
  },
  data() {
    return {
      role: getRoleFromToken() || 'ROLE_REDACTOR',
      allHeaders: [
        { text: 'id', value: 'id', sortable: true },
        { text: 'title', value: 'title', sortable: true },
        { text: 'anep', value: 'anep', sortable: false },
        { text: 'journalParution', value: 'journalParution', sortable: true },
        { text: 'type', value: 'tendersTypeAttachmentName', sortable: false },
        // { text: 'lieuRetraitCDC', value: 'lieuRetraitCDC', sortable: false },
        { text: 'dateParution', value: 'dateParution', sortable: false },
        { text: 'dateEcheance', value: 'dateEcheance', sortable: false },
        { text: 'wilaya', value: 'wilayaMarchee.name', sortable: false },
        { text: 'secteurs', value: 'secteurDTOS', sortable: false },
        { text: 'organisme', value: 'organisme.organismName', sortable: false },
        // { text: 'categorie', value: 'tendersTypeName', sortable: false },
        // { text: 'resultat', value: 'resultName', sortable: false },
        { text: "Créé le", value: "created", sortable: true, roles: ["ROLE_ADMIN"] },
        { text: "Créé par", value: "createdBy", sortable: true, roles: ["ROLE_ADMIN"] },
        { text: "Modifié le", value: "updated", sortable: true, roles: ["ROLE_ADMIN"] },
        { text: "Modifié par", value: "updatedBy", sortable: true, roles: ["ROLE_ADMIN"] },
        { text: 'Action', value: 'actionsWithNotif', sortable: false },
      ],
      types: [
        { id: 1, name: "Appel d'offre" },
        { id: 2, name: "Consultation"},
        { id: 3, name: "Attribution"},
        { id: 4, name: "Concours"},
        { id: 5, name: "Annulation"},
        { id: 6, name: "Prorogation de délai" },
        { id: 7, name: 'Infructuosité'},
        { id: 8, name: 'Mise en demeur/Résiliation' },
      ],
      bool: false,
      color: '',
      notice: '',
      itemId: '',
      loading: false,
      loadingText: '...',
      dialog: false,
      dialogTitle: 'Confirmation',
      dialogText: 'Voulez-vous vraiment supprimer ce marché ?',
      page: 1,
      rightDrawer: false,
      wilayas: wilaya,
      secteurs: SECTORS,
    };
  },
  watch: {
    page: function () {
      let ar = { token: this.getToken, page: this.page };
      this.fetchAllMarchees(ar);
    },
  },
  computed: {
      headers() {
        return this.allHeaders.filter(col => {
          return !col.roles || col.roles.includes(this.role);
        });
      },
      ...mapGetters(['allMarchees', 'getUser', 'getToken', 'getMarcheeTotalCount', 'getMarcheeParams']),
      formattedMarchees() {
        return this.allMarchees.map(marche => {
          const formattedMarche = {};
          Object.keys(marche).forEach(key => {
            const value = marche[key];
            if (key === 'created' || key === 'updated') {
              formattedMarche[key] = value ? formatDate(value) : '/';
            }
            else {
              formattedMarche[key] = value !== null && value !== undefined && value !== '' ? value : '/';
            }
          });
          return formattedMarche;
        });
      }
  },
  methods: {
    ...mapActions(['fetchAllMarchees', 'whoAMI', 'deleteMarchee', 'sendNotifs', 'clearMarcheeFilters']),
    add() {
      console.log('ikechmed');
      this.$router.push({ name: 'MarcheeForm' });
    },
    edit(item) {
      console.log(item);
      this.$router.push(`/marchees/${item.id}`);
    },
    remove(item) {
      this.itemId = item.id;
      this.dialog = true;
    },
    notify(item) {
      console.log(item);
      let notif = { title: item.title, secteurId: item.secteurId, organismeName: item.organisme.organismName };
      let ar = { data: notif, token: this.getToken };
      console.log(notif);
      this.sendNotifs(ar);
    },
    async onConfirm() {
      this.dialog = false;
      this.loading = true;
      let ar = { id: this.itemId, token: this.getToken };
      await this.deleteMarchee(ar).then(() => {
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
    onCancel() {
      this.dialog = false;
      this.itemId = '';
    },
    pagesLength(n) {
      return n <= 20 ? 1 : n <= 40 ? 2 : Math.floor(n / 20) + 1;
    },
    search() {
      this.rightDrawer = !this.rightDrawer;
    },
    cancelSearch() {
      this.clearMarcheeFilters();
      let ar = { token: this.getToken, page: this.page };
      this.fetchAllMarchees(ar);
      this.rightDrawer = false;
    },
    searchData() {
      this.rightDrawer = false;
      let ar = { token: this.getToken, page: this.page };
      this.fetchAllMarchees(ar);
    }
  },
  created() {
    this.whoAMI();
    if (this.getUser != null) {
      let ar = { token: this.getToken, page: this.page };
      this.fetchAllMarchees(ar);
    }
  },
  updated() {},
};
</script>

<style scoped>
.v-container {
  max-width: 95%;
  margin: auto;
}
.custom-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.85);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.loading-text {
  color: #1976D2;
  font-weight: 500;
  font-size: 16px;
}
</style>
