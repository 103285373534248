<template>
  <v-container fluid>
    <v-flex xs12>
      <v-card class="elevation-0">
        <v-card-title class="title">
          {{title}}
          <v-spacer></v-spacer>
          <v-btn elevation="4" fab small dark class="grey mr-2" @click.native="cancel()">
            <v-icon dark="">mdi-close-circle-outline</v-icon>
          </v-btn>
          &nbsp;
          <v-btn elevation="4" fab small dark class="blue" @click.native="save()">
            <v-icon>mdi-content-save-all</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div v-if="loading" class="custom-loader">
            <v-progress-circular
              indeterminate
              size="64"
              color="primary"
              class="mb-3"
            ></v-progress-circular>
            <div class="loading-text mt-3">{{ loadingText }}</div>
          </div>
          <v-container fluid grid-list-sm>
            <v-layout row wrap>
              <v-flex md3 sm12> </v-flex>
              <v-flex md6 sm12>
                <v-container fluid grid-list-sm>
                  <v-layout row wrap>
                    <v-flex md12 sm12 xs12 class="mx-1 px-0">
                      <v-text-field
                        name="username"
                        label="Nom utilisateur *"
                        hint="Nom is required"
                        v-model="oneUser.loginUser"
                        class="input-group--focused"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12 sm12 class="mx-1 px-0">
                      <v-text-field
                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min]"
                        :type="show2 ? 'text' : 'password'"
                        v-model="oneUser.passwordUser"
                        name="input-10-2"
                        label="Mot de passe *"
                        hint="At least 8 characters"
                        class="input-group--focused"
                        @click:append="show2 = !show2"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12 sm12 class="mx-1 px-0">
                       <v-text-field
                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.passwordMatch]"
                        :type="show2 ? 'text' : 'password'"
                        v-model="oneUser.password"
                        name="input-10-2"
                        label="Confirmation mot de passe *"
                        hint="At least 8 characters"
                        class="input-group--focused"
                        @click:append="show2 = !show2"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12 sm12 class="mx-1 px-0">
                      <v-text-field
                        name="firstname"
                        label="Nom *"
                        maxlength="10"
                        hint="name is required"
                        v-model="oneUser.firstName"
                        class="input-group--focused"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12 sm12 class="mx-1 px-0">
                      <v-text-field
                        name="lastname"
                        label="Prenom *"
                        maxlength="10"
                        hint="Last name is required"
                        v-model="oneUser.lastName"
                        class="input-group--focused"
                        required
                      ></v-text-field>
                    </v-flex>

                    <v-flex md12 sm12 class="mx-1 px-0">
                      <v-text-field
                        name="email"
                        label="Email *"
                        :rules="emailRules"
                        v-model="oneUser.email"
                        class="input-group--focused"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12 sm12 xs12 class="mx-1 px-0" v-if=" currentRole === 'ROLE_ADMIN'">
                      <v-select
                        :items="roles"
                        item-text="label"
                        item-value="value"
                        label="Role utilisateur *"
                        v-model="oneUser.role"
                        class="input-group--focused"
                        required
                      ></v-select>
                    </v-flex>
                    <v-flex md12 sm12 class="mx-1 px-0">
                      <v-text-field
                        name="phoneNumber"
                        label="Numéro de téléphone *"
                        type="text"
                        v-model="oneUser.userExtraDTO.numeroTel"
                        class="input-group--focused"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12 sm12 xs12 class="mx-1 px-0">
                      <v-select
                        :items="secteurs"
                        item-text="name"
                        item-value="id"
                        label="Secteurs *"
                        v-model="oneUser.userExtraDTO.secteurs"
                        multiple
                        chips
                      ></v-select>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-flex>
              <v-flex md3 sm12> </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import wilaya from '../Wilaya';
import SECTORS from '../Sectors';
import { getRoleFromToken } from '../utils/jwtUtils';
import { handleFrontEndValidationError } from '../utils/responseHandler';
export default {
  data() {
    return {
      currentRole: getRoleFromToken() || 'ROLE_ADMIN',
      wilayas: wilaya,
      items: ['occupe', 'libre'],
      title: '',
      bool: false,
      color: '',
      notice: '',
      password: '',
      passwordConfirmation: '',
      loading: false,
      loadingText: 'Enregistrement en cours...',
      secteurs: SECTORS,
      roles: [
        { value: 'ROLE_USER', label: 'Utilisateur' },
        { value: 'ROLE_ADMIN', label: 'Administrateur' },
        { value: 'ROLE_REDACTOR', label: 'Rédacteur' },
        { value: 'ROLE_SALES', label: 'Commercial' },
      ],
      formules: [
        { id: 1, item: 'Comfort     -- 02 Secteurs', itemName: 'Comfort' },
        { id: 2, item: 'Premium     -- 05 Secteurs', itemName: 'Premium' },
        { id: 3, item: 'Business    -- Tous les secteurs', itemName: 'Business' },
      ],
       show2: false,
       rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 8 || 'Min 8 characters',
          passwordMatch: v => v == this.oneUser.passwordUser || 'Password mismatch',
        },
         emailRules: [ 
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
      ]
    };
  },
  computed: mapGetters(['oneUser', 'getUser', 'getToken']),
  methods: {
    ...mapActions(['saveUser', 'whoAMI']),
    cancel() {
      this.$router.push({ name: 'Utilisateur' });
    },
    async save() {
      this.loading = true;
      this.loadingText = this.$route.params.id ? 'Mise à jour en cours...' : 'Enregistrement en cours...';
      const validation = this.validateUser(this.oneUser);
      if (validation !== true) {this.loading = false; return;}
      let ar = {user: this.oneUser, token: this.getToken}
      await this.saveUser(ar).then(() => {
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    validateUser(oneUser){
          if (!oneUser.loginUser) {
            return handleFrontEndValidationError("Le Nom utilisateur est obligatoire");
          }
          if (!oneUser.passwordUser) {
            return handleFrontEndValidationError("Le mot de passe est obligatoire");
          }
          if (!oneUser.password) {
            return handleFrontEndValidationError("La confirmation du mot de passe est obligatoire");
          }
          if (oneUser.password !== oneUser.passwordUser) {
            return handleFrontEndValidationError("La confirmation du mot de passe ne correspond pas");
          }
          if (!oneUser.firstName) {
            return handleFrontEndValidationError("Le nom est obligatoire");
          }
          if (!oneUser.lastName) {
            return handleFrontEndValidationError("Le prenom est obligatoire");
          }
          if (!oneUser.email) {
            return handleFrontEndValidationError("L'email est obligatoire");
          }
          if (!oneUser.userExtraDTO.numeroTel) {
            return handleFrontEndValidationError("Le numero de telephone est obligatoire");
          }
          if (!oneUser.userExtraDTO.secteurs) {
            return handleFrontEndValidationError("Le secteur est obligatoire");
          }
          return true;
      }
  },
  mounted() {
      if (this.$route.params.id) {
        this.title = 'Modifier Utilisateur';
      } else {
        this.title = 'Nouveau Utilisateur';
      }
  },
  created() {
    if (this.$route.params.id) {
      let ar = { id: this.$route.params.id, token: this.getToken };
      this.getMarcheeById(ar).then(e=> {
        console.log(e);
        this.entries.push(this.oneMarchee.organisme)
      })
    }
  },
};
</script>

<style scoped>
.custom-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.85);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.loading-text {
  color: #1976D2;
  font-weight: 500;
  font-size: 16px;
}
</style>